import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpErrorResponse,
    HttpHandler,
    HttpEvent,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { tap } from 'rxjs/internal/operators/tap';
import { CommonService } from './services/common.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
    sitename: string = '';

    constructor(
        private router: Router, private cs: CommonService
    ) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                const x = val.url.split('/');
                if (x.length >= 4) {
                    const y = x[3].split('-');
                    y.shift();
                    y.pop();
                    const z = y.toString().replace(/\,/g, ' ');
                    if (this.sitename === '') this.sitename = z;
                    // console.log(this.sitename, 'this.sitename');

                }

            }
        });
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
        let access_token = localStorage.getItem('token');
        if (!request.headers.get('Authorization') && !environment.exceptionURLs.find(url => request.url.startsWith(url)))
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${access_token}`,

                }, setParams: {
                    site: this.sitename
                }
            });

        return next.handle(request).pipe(tap({

            next: (event) => {
                if (event instanceof HttpResponse) {
                    // console.log(event, 'event');
                }
                return event;
            },
            error: (err: HttpErrorResponse) => {
                // if (err?.error?.message) this.cs.openSnackBar(err?.error?.message, 'Dismiss')
                if (err.error.message === "The token has been blacklisted") {
                    localStorage.setItem('temp_url', localStorage.getItem('url') || '/')
                    localStorage.clear();
                    this.router.navigate(['/login']);
                };
                if (err.error.message === "User status inactive") {
                    localStorage.setItem('temp_url', localStorage.getItem('url') || '/')
                    localStorage.clear();
                    this.router.navigate(['/login']);
                };

                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 401:
                            localStorage.setItem('temp_url', localStorage.getItem('url') || '/')
                            localStorage.removeItem('token');
                            this.router.navigate(['/login']);
                            break;
                        case 402:
                            localStorage.setItem('temp_url', localStorage.getItem('url') || '/')
                            localStorage.removeItem('token');
                            this.router.navigate(['/login']);
                            break;
                        // case 500:
                        // localStorage.setItem('temp_url', localStorage.getItem('url') || '/')
                        // localStorage.removeItem('token');
                        // this.router.navigate(['/login']);
                        // break;
                        default:
                            // this.router.navigate(['info/error']);
                            return;
                    }

                }
                return err;
            }
        }
        ));

    }
}


