import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { ApiService } from './services/api.service';
import { serviceBaseUrl } from 'src/environments/environment';
declare const gtag: Function;
// import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  pipe = new DatePipe('en-GB');
  title = 'oak-insights';
  use_email = localStorage.getItem('email');
  formattedDate: any;

  constructor(
    private router: Router,
    private datePipe: DatePipe,
    private apiService: ApiService,
    // private socket: Socket
  ) {

    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        localStorage.setItem('url', val.url);
      }

    });
    router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        /** START : Code to Track Page View  */
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
        });
        /** END */
      });

    this.emailcheck();
  }

  emailcheck() {
    if (this.use_email) {
      this.formattedDate = this.pipe.transform(new Date(), 'YYYY-MM-dd ', 'UTC');
      if (this.formattedDate !== localStorage.getItem('date') && serviceBaseUrl as any == 'https://api.oak-insights.com/') {
        this.apiService.postlastlogin(this.use_email, this.formattedDate).subscribe((data: any) => {
          localStorage.setItem('date', this.formattedDate);
        })
      }
    }
  }


}


