// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  RecommendationsApi: 'https://prodv2api.oak-alerts.com/',
  NewsApi: 'https://gnews.io/api/v4/search?q=energy+sustainability&token=6cf3e4b0d8356ddd9768dd71b41a01d6',
  WeatherApi: 'https://dataservice.accuweather.com/locations/v1/search.json?apikey=ZsKPWxpmrS7WYgVR3ooJ8goDcby8CtpH&q=',
  EquipmentApi: 'https://oak-alerts.com/',
  SharedAlertsApi: 'https://prodv2api.oak-alerts.com/',
  SavingApi: 'https://api.oak-insights.com/',
  SavingApidemo: 'https://api.oak-insightsdemo.com/',
  production: false,
  exceptionURLs: [
    'https://dataservice.accuweather.com',
    'https://gnews.io',
    'https://dataservice.accuweather.com'
  ]
};

//export const serviceBaseUrl = 'https://api.oak-insights.com/';
export const serviceBaseUrl = 'https://api.oak-insightsdemo.com/';

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
