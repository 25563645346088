import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  pipe = new DatePipe('en-GB');
  slug = '';
  private premium = new BehaviorSubject(false);
  public getPremium = this.premium.asObservable();
  constructor(private _snackBar: MatSnackBar) {
    this.getPipeDate(new Date(), '', 'UTC');
    this.checkPremiumUser();
  }

  private checkPremiumUser() {
    //subscribe
    let x = false;
    setInterval(() => {
      x = !x;
      this.premium.next(x);
    }, 1000);
  }

  getPipeDate(date: any, type: string, zone: string = 'UTC') {
    return date ? this.pipe?.transform(
      +(new Date(date || new Date())),
      type,
      zone
    ) : 0;
  }

  openSnackBar(message: string, action: string = 'Cancel') {
    if (message) this._snackBar.open(message, action, { duration: 3000 });
  }
}
